import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import ModularBlocks from '../components/ModularBlocks';

const DefaultPageTemplate = ({
  data: { datoCmsPage },
  location,
  pageContext: { locale },
}) => {
  const {
    seoMetaTags,
    slugLocales,
    noIndex,
    contactFormLandingPage,
    title,
    banner,
    modularBlocks,
  } = datoCmsPage;

  return (
    <Layout
      seo={seoMetaTags}
      locale={locale}
      slugLocales={slugLocales}
      noIndex={contactFormLandingPage || noIndex}
      page={datoCmsPage}
      isContactFormLandingPage={contactFormLandingPage}
    >
      <main>
        <Banner
          heading={title}
          text={banner && banner.text}
          link={banner && banner.link}
          image={banner && banner.image}
          contactFormLandingPage={contactFormLandingPage}
        />
        <ModularBlocks
          items={modularBlocks}
          locationData={location}
          locale={locale}
        />
      </main>
    </Layout>
  );
};

export const DefaultPageTemplateQuery = graphql`
  query DefaultPageTemplateQuery($id: String!) {
    datoCmsPage(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      slugLocales: _allSlugLocales {
        locale
        value
      }
      noIndex
      contactFormLandingPage
      title
      banner {
        ...BannerFragment
      }
      modularBlocks {
        ...AccordionModularBlockFragment
        ...AnchoredContentBlocksModularBlockFragment
        ...CtaStripModularBlockFragment
        ...ContactCtaStripModularBlockFragment
        ...ContainedImageModularBlockFragment
        ...ContentBlocksModularBlockFragment
        ...ContentModularBlockFragment
        ...ContentFeaturedLinksImageLinkCardsModularBlockFragment
        ...DiversityTravelAppModularBlockFragment
        ...ExternalVideoModularBlockFragment
        ...FeaturedLogosModularBlockFragment
        ...FeaturedResourcesModularBlockFragment
        ...FeaturedTeamModularBlockFragment
        ...FeaturedTestimonialsModularBlockFragment
        ...FormModularBlockFragment
        ...FullWidthImageModularBlockFragment
        ...IconContentBlocksModularBlockFragment
        ...ImageContentModularBlockFragment
        ...ImageCtaCardsModularBlockFragment
        ...ImageGalleryModularBlockFragment
        ...ImageLinkCardsModularBlockFragment
        ...InternalVideoModularBlockFragment
        ...LocationsModularBlockFragment
        ...LogoContentBlocksModularBlockFragment
        ...TabsModularBlockFragment
        ...TeamListingModularBlockFragment
        ...VideoPlayerModularBlockFragment
        ...VisaWidgetModularBlockFragment
      }
      ...LinkFragment
    }
  }
`;

export default DefaultPageTemplate;
